.list {
  padding: 8px 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  /*background: #fff;*/
}

@media (min-width: 46.25em) {
  .list {
    width: 30rem;
  }
}

.list__list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 48px;
  width: 44%;
  height: auto;
  margin: 3%;
  position: relative;
  overflow: hidden;
  transition: all .4s cubic-bezier(.25,.8,.25,1);
  box-shadow: 0 1px 5px -3px rgba(0,0,0, 0.85);
}


@media (min-width: 51em) {
  .list {
    width: 50rem;
  }
  .list__list-item {
    width: 27%;
  }
}


/* To make the list items square! This hack sets the height equal to the width */
.list__list-item:after {
  content: ' ';
  padding-top: 100%;
  display: table;
}

.list__list-item:hover {
  box-shadow: 0 2px 5px 0 rgba(0,0,0,.32);
  transform: scale(1.02);
}

.list__list-item:before {
  content: " ";
  background:
    linear-gradient( rgba(184, 211, 230, 0.05), rgba(184, 211, 230, .6) ),
    url('./button-bg.jpg') no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: .22;
}

.list-item__button {
  padding: 0 16px;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: inherit;
  justify-content: center;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  z-index: 1;
  word-break: break-word;
  color: #fff;
  text-shadow: 0 2px 7px rgba(0, 0, 0, 0.75);
}

@media (min-width: 23.125rem) {
  .list-item__button {
    font-size: 2rem;
  }
}

@media (min-width: 25rem) {
  .list-item__button {
    font-size: 2.5rem;
  }
}



.list-item:hover {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.32);
  transform: scale(1.02);
}

.list-item__inner {
  width: 100%;
  min-height: inherit;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
}

.list-item__inner:before {
  content: "";
  min-height: 48px;
  visibility: hidden;
  display: inline-block;
}
