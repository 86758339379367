body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: #f9f9f9;
}

header {
  border-top: 8px solid #b8d3e6;
  padding: 1.3rem;
  background: #f9f9f9;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  /* The list item before:images are z-index: 1 so we need the header to be on top of them */
  z-index: 2;
}

h1 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #222;
}
h1 a {
  text-decoration: none;
  color: inherit;
}

.header__title {
  margin: 0;
  text-align: left;
  letter-spacing: 0;
  font-weight: normal;
}

.header__home-link span {
  color: #0161ff /*#4bc6df*/;
  font-weight: 200;
}

.content {
  /* Allow some air on the sides on mobile devices */
  padding: 0 10px;
  /* Account for the fixed header */
  margin-top: 90px;
}

.content h1 {
  text-align: center;
  font-weight: 200;
  letter-spacing: 0.05em;
  font-size: 1.5rem;
}

@media (min-width: 46.25rem) {
  .content h1 {
    font-size: 2rem;
  }
}

