.hidden {
  display: none;
}

/* TODO: Create board width/height depending on device orientation and dimensions */

.crossword {
  padding: 1.25rem 0.625rem;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.crossword-container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.crossword-board {
  width: 100%;
  position: relative;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

@media (min-width: 46.25em) {
  .crossword-container,
  .crossword-board {
      width: 30.0625rem;
  }
}

h3 {
  margin-top: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 200;
  letter-spacing: 0.1em;
  color: #222;
  font-size: 1.5rem;
}

@media (min-width: 46.25rem) {
  h3 {
    font-size: 2rem;
  }
}

.crossword__grid {
  display: block;
}

.crossword__cell {
  cursor: pointer;
  fill: #ffffff;
}

.crossword__cell.crossword__cell--highlighted {
  fill: #4bc6df;
}
.crossword__cell.crossword__cell--selected {
  fill:#cdeff6;
}

.crossword__cell-number, .crossword__cell-text {
  fill: #222222;
}
.crossword__cell-text {
  font-size: 1.5rem;
  font-family: 'Indie Flower', cursive;
  font-weight: bold;
  display: inline-block;
  opacity: 1;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: opacity .15s ease-in;
  transition: opacity .15s ease-in;
}

.crossword__cell-number {
  font-family: "Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  font-size: 0.625rem;
  user-select: none;
  cursor: pointer;
}

.crossword__hidden-input-wrapper {
  position: absolute;
}

.crossword__hidden-input {
  border: 0;
  padding: 0;
  outline: none;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: transparent;
  font-size: 1.5rem;
  font-family: Georgia,serif;
  font-weight: 800;
  text-anchor: middle;
}

button {
  margin-top: 2rem;
  border: none;
  height: 36px;
  min-width: 88px;
  color: rgba(0, 0, 0, 0.78);
  font-size: 14px;
  text-transform: uppercase;
  cursor: pointer;
  padding: 0 16px;
  border-radius: 1px;
  outline: none;
  letter-spacing: .01em;
  font-weight: 500;
  transition: box-shadow .4s cubic-bezier(.25,.8,.25,1),background-color .4s cubic-bezier(.25,.8,.25,1);
}

button:hover {
  background-color: rgba(158,158,158,0.2);
}
button:active {
  background-color: rgba(153,153,153,0.4);
}
